import logo from "../utils/245-long.gif";

const LoadingScreen = () => {
  return (
    <div className="image-container">
        <img src={logo} alt="loading..." />
    </div>
  );
};

export default LoadingScreen;
