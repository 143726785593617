import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTexts from './locales/en.json';
import swedishTexts from './locales/sv.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: englishTexts,
      },
      sv: {
        translations: swedishTexts,
      },
    },
    // fallbackLng: 'en',
    detection: {
      lookUpQuerystring: 'lang',
      lookupLocalStorage: 'lang',

      order: ['localStorage', 'querystring', 'navigator'],
      caches: ['localStorage'],
      convertDetectedLanguage: (lng) => lng.split('-')[0]
      // lookupLocalStorage: 'lang',
    },
    supportedLngs: ['en', 'sv'],
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });
