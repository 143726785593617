import React from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}>
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
