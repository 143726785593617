import { FC, useState } from "react";
import { TFunction, withTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { DocumentsType } from "../../utils/types";

type FileSelectorProps = {
  label: string;
  name: string;
  handleChange: React.ChangeEventHandler<any>;
  handleBlur: React.FocusEventHandler<any>;
  value: string;
  error?: string;
  touched?: boolean;
  t: TFunction<"translation", undefined>;
  setCVDocument: React.Dispatch<React.SetStateAction<File | null>>;
  setCLDocument: React.Dispatch<React.SetStateAction<File | null>>;
};

const FileSelector: FC<FileSelectorProps> = ({
  label,
  name,
  handleChange,
  handleBlur,
  value,
  error,
  touched,
  t,
  setCVDocument,
  setCLDocument
}) => {
  const [fileSizeError, setFileSizeError] = useState(false);

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const checkIfFilesAreTooBig = (value: any): boolean => {
    if (value) {
      const size = value.size / 1024 / 1024
      if (size > 1) {
        return true;
      }
    }
    return false;
  }

  const handleFileRead = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (checkIfFilesAreTooBig(file)) {
      setFileSizeError(true);
    } else {
      if (e.target.name === "documents.cv") {
        setCVDocument(file);
      }
      else {
        setCLDocument(file);
      }
      setFileSizeError(false);
    }
  };


  return (
    <Form.Group className="form-group">
      <Form.Label>{label}</Form.Label>
      <input
        type="file"
        className={`form-control ${error && touched && "error"}`}
        name={name}
        onChange={ e => {
          handleFileRead(e);
          handleChange(e);
        }}
        onBlur={ e => {
          handleFileRead(e);
          handleBlur(e);
        }}
        value={value}
      />
      {error && touched && (
        <Form.Text className="error-message">{t(error)}</Form.Text>
      )}
      {!error && touched && fileSizeError && (
        <Form.Text className="error-message">{t("Error_CV_CoverLetter_Invalid_File_Size")}</Form.Text>
      )}
    </Form.Group>
  );
};

export default withTranslation()(FileSelector);
