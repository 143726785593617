import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;

export const get = async (url: string) => {
    const requestUrl = baseUrl + url;
    return await axios.get(requestUrl);
}

export const post = async (url: string, payload: object) => {
    const requestUrl = baseUrl + url;
    return await axios.post(requestUrl, payload);
}

export const postForm = async (url: string, payload: FormData) => {
    const requestUrl = baseUrl + url;
    return await axios.post(requestUrl, payload);
}