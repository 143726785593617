import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import globeIcon from './../utils/bi_globe2.svg';

const options = [
  { value: 'en', label: 'English' },
  { value: 'sv', label: 'Svenska' },
];

const Control = (props: any) => (
  <components.Control {...props}>
    <img src={globeIcon} alt="globe-icon" className="globe-icon" />
    {props.children}
  </components.Control>
);

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(i18n.language);

  useEffect(() => {
    setSelectedOption(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    i18n.changeLanguage(selectedOption);
  }, [selectedOption, i18n.language]);

  return (
    <div className="language-selector-wrapper">
      <Select
        className="language-selector"
        options={options}
        components={{ Control, IndicatorSeparator: () => null }}
        defaultValue={options.find((option) => option.value === selectedOption)}
        isSearchable={false}
        onChange={(option) =>
          option && option.value && setSelectedOption(option.value)
        }
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#e9ecef',
            primary: '#21252990',
          },
        })}
      />
    </div>
  );
};

export default LanguageSelector;
