import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

type SuccessModalProps = {
  interviewUrl: string;
};

const SuccessModal: FC<SuccessModalProps> = ({ interviewUrl }) => {
  const { t } = useTranslation();

  return (
    <div className="success-modal">
      <div className="title">{t("SuccessModal_Title")}</div>
      <div className="subtitle">{t("SuccessModal_Subtitle")}</div>
      <div className="or-section">
        <div className="or-seperator-line" />
        <div className="or-text">{t("SuccessModal_OrText")}</div>
      </div>
      <div className="btn-wrapper">
        <a href={interviewUrl} target="_blank" rel="noreferrer">
          <Button variant="primary">{t("ButtonText_StartInterviewNow")}</Button>
        </a>
      </div>
    </div>
  );
};

export default SuccessModal;
