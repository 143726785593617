import { useEffect, useRef, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { JobDetail } from "../utils/types";

type TopSectionProps = {
  jobInformation: JobDetail;
};

const TopSection: FC<TopSectionProps> = ({ jobInformation }) => {
  const { t } = useTranslation();
  const roleDescriptionRef = useRef(null);
  const [isExtendedDescription, setIsExtendedDescription] = useState(false);
  const [showMoreStatus, setShowMoreStatus] = useState(false);

  useEffect(() => {
    if (roleDescriptionRef.current) {
      if (
        roleDescriptionRef.current["scrollHeight"] >
        roleDescriptionRef.current["clientHeight"]
      )
        setIsExtendedDescription(true);
      else setIsExtendedDescription(false);
    }
  }, []);

  const renderShowMoreComponent = () => {
    if (isExtendedDescription) {
      return (
        <div
          className="show-more"
          onClick={() => setShowMoreStatus(!showMoreStatus)}
        >
          {showMoreStatus ? t("ShowLess") : t("ShowMore")}
        </div>
      );
    }
  };

  return (
    <div className="top-section">
      <div className="title">{t("TopSection_JobApplicationFor")}</div>
      <div className="sub-title">
        <span className="job-title">"{jobInformation.jobTitle}"</span>
        <span className="company-name">{` ${t(
          "TopSection_At",
        )} ${jobInformation.clientTitle}`}</span>
      </div>
      {jobInformation.formOption.logo ? <img className="pt-3" src={jobInformation.formOption.logo} alt="company-logo" /> : <div></div>}
      
      <p
        ref={roleDescriptionRef}
        className={`${showMoreStatus && "extended"} ${
          !isExtendedDescription && "spacing"
        }`}
      >
        {jobInformation.jobDescription}
      </p>
      {renderShowMoreComponent()}
    </div>
  );
};

export default TopSection;
