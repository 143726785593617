import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikTouched } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { AddressType, JobDetail } from "../../utils/types";
import countries from "../../utils/countries.json";
import Textbox from "./Textbox";

type AddressProps = {
  handleChange: React.ChangeEventHandler<any>;
  handleBlur: React.FocusEventHandler<any>;
  values: AddressType;
  errors: FormikErrors<AddressType> | undefined;
  touched: FormikTouched<AddressType> | undefined;
  jobInformation: JobDetail;
};

const Address: FC<AddressProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  jobInformation
}) => {
  const { t } = useTranslation();

  return (
    <>
    {(jobInformation.formOption.address != null || jobInformation.formOption.zipCode != null) && ( 
      <Row>
        {jobInformation.formOption.address != null && (
        <Col md="9">
          <Textbox
            label={`${t("Label_Street")}${jobInformation.formOption.address ? "*" : ""}`}
            name={"address.street"}
            placeholder={t("Placeholder_Street")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.street}
            error={errors?.street}
            touched={touched?.street}
          />
        </Col>
        )}
        {jobInformation.formOption.zipCode != null && (
        <Col md="3">
          <Textbox
            type="number"
            label={`${t("Label_ZIPCode")}${jobInformation.formOption.zipCode ? "*" : ""}`}
            name={"address.zipCode"}
            placeholder={t("Placeholder_ZIPCode")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.zipCode}
            error={errors?.zipCode}
            touched={touched?.zipCode}
          />
        </Col>
        )}
      </Row>
    )}
    {(jobInformation.formOption.city != null || jobInformation.formOption.country != null) && (
      <Row>
        {jobInformation.formOption.city != null && (
        <Col md="6">
          <Textbox
            label={`${t("Label_City")}${jobInformation.formOption.city ? "*" : ""}`}
            name={"address.city"}
            placeholder={t("Placeholder_City")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.city}
            error={errors?.city}
            touched={touched?.city}
          />
        </Col>
        )}
        {jobInformation.formOption.country != null && (
        <Col md="6">
          <Form.Group className="form-group">
            <Form.Label>{`${t("Label_Country")}${jobInformation.formOption.country ? "*" : ""}`}</Form.Label>
            <Form.Select
              className={errors?.country && touched?.country ? "error" : ""}
              aria-label="Country selector"
              onChange={handleChange}
              onBlur={handleBlur}
              name="address.country"
              value={values.country}
            >
              <option value="" disabled>
                {t("Placeholder_Country")}
              </option>
              {countries.map(country => (
                <option value={country.code} key={country.code}>
                  {country.name}
                </option>
              ))}
            </Form.Select>
            {errors?.country && touched?.country && (
              <Form.Text className="error-message">
                {t(errors?.country)}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        )}
      </Row>
    )}
    </>
  );
};

export default Address;
