export const b64toBlob = async (base64: string) =>
    await fetch(base64).then(res => res.blob())


export const getFileExtension = (base64: string) => {
    const mime = base64.substring("data:".length, base64.indexOf(";base64"));
    if (mime == "application/pdf") return ".pdf";
    else return ".docx";
} 

export const getFileExtentionByType = (type: string) => {
    if (type == "application/pdf") return ".pdf";
    else return ".docx";
}
    