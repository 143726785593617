import { FC, useMemo } from 'react';
import { TFunction, useTranslation, withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import countries from '../../utils/countries.json';

interface PhoneNumberProps {
  label: string;
  names: { countryCode: string; phoneNumber: string };
  placeholder: string;
  countryCodePlaceholder: string;
  handleChange: React.ChangeEventHandler<any>;
  handleBlur: React.FocusEventHandler<any>;
  values: { countryCode: string; phoneNumber: number | string };
  errors?: { countryCode?: string; phoneNumber?: string };
  touched?: { countryCode?: boolean; phoneNumber?: boolean };
  t: TFunction<'translation', undefined>;
}

const PhoneNumber: FC<PhoneNumberProps> = ({
  label,
  names,
  placeholder,
  countryCodePlaceholder,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  t,
}) => {
  const countryCodes = useMemo(() => {
    const countryCodeList = countries
      .map((country) => ({
        code: country.code,
        dialCode: country.dial_code,
        name: t(`Countries.${country.code}`),
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    return countryCodeList;
  }, []);

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //Only allow numbers
    const value = e.target.value.replace(/\D/g, '');
    e.target.value = value;

    handleChange(e);
  };

  return (
    <Form.Group className="form-group">
      <Form.Label>{label}</Form.Label>
      <div className="phone-input-wrapper">
        <div className="country-code-input">
          <Form.Select
            onChange={handleChange}
            onBlur={handleBlur}
            name={names.countryCode}
            value={values.countryCode}
          >
            <option value="" disabled>
              {countryCodePlaceholder}
            </option>
            {countryCodes.map((country, index) => (
              <option value={country.dialCode} key={index}>
                {country.name} ({country.dialCode})
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="phone-number-input">
          <Form.Control
            type="text"
            className={
              errors?.phoneNumber && touched?.phoneNumber ? 'error' : ''
            }
            name={names.phoneNumber}
            placeholder={placeholder}
            onChange={handleNumberChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            autoComplete="off"
          />
          {errors?.phoneNumber && touched?.phoneNumber && (
            <Form.Text className="error-message">
              {t(errors?.phoneNumber)}
            </Form.Text>
          )}
        </div>
      </div>
    </Form.Group>
  );
};

export default withTranslation()(PhoneNumber);
