import * as Yup from 'yup';
import { FormOption } from './types';
import parsePhoneNumber from 'libphonenumber-js';

const getValidationSchema = (formFields: FormOption) => {
  return Yup.object().shape({
    general: Yup.object().shape({
      firstName: Yup.string().required('Error_FirstName_Required'),
      surname: Yup.string().required('Error_Surname_Required'),
      emailAddress: Yup.string()
        .required('Error_EmailAddress_Required')
        .email('Error_EmailAddress_Invalid'),
      phoneNumber: phoneNumberValidation(formFields.phone),
      linkedIn: linkedInValidation(formFields.linkedIn),
    }),
    address: Yup.object().shape(
      {
        street: validateSimpleString(
          formFields.address,
          'Error_Address_Required'
        ),
        zipCode: validateSimpleString(
          formFields.zipCode,
          'Error_ZipCode_Required'
        ),
        city: validateSimpleString(formFields.city, 'Error_City_Required'),
        country: validateSimpleString(
          formFields.country,
          'Error_Country_Required'
        ),
      },
      [
        ['street', 'zipCode'],
        ['street', 'city'],
        ['street', 'country'],
        ['zipCode', 'city'],
        ['zipCode', 'country'],
        ['city', 'country'],
      ]
    ),
    documents: Yup.object().shape({
      cv: cvValidation(formFields.cv),
      coverLetter: coverLetterValidation(formFields.coverLetter),
    }),
  });
};

const phoneNumberValidation = (isRequired = false) => {
  // Note: libphonenumber-js is used to validate phone numbers but is done elsewhere as it requires both countryCode and phoneNumber
  const validator = Yup.string();

  return isRequired
    ? validator.required('Error_PhoneNumber_Required')
    : validator;
};

const linkedInValidation = (value?: boolean) => {
  //TODO: Refactor to use Yup.url() as the base of the validation
  if (value) {
    return Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Error_LinkedIn_Invalid'
      )
      .required('Error_LinkedIn_Required');
  } else {
    return Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Error_LinkedIn_Invalid'
    );
  }
};

const validateSimpleString = (
  isRequired: boolean | undefined,
  errorMessageName: string
) => {
  if (isRequired) {
    return Yup.string().required(errorMessageName);
  }

  return Yup.string();
};

const cvValidation = (value?: boolean) => {
  if (value) {
    return Yup.string()
      .matches(
        /^.*\.(doc|DOC|docx|DOCX|pdf|PDF)$/,
        'Error_CV_CoverLetter_Invalid_File'
      )
      .required('Error_CV_Required');
  } else {
    return Yup.string().matches(
      /^.*\.(doc|DOC|docx|DOCX|pdf|PDF)$/,
      'Error_CV_CoverLetter_Invalid_File'
    );
  }
};

const coverLetterValidation = (value?: boolean) => {
  if (value) {
    return Yup.string()
      .matches(
        /^.*\.(doc|DOC|docx|DOCX|pdf|PDF)$/,
        'Error_CV_CoverLetter_Invalid_File'
      )
      .required('Error_CoverLetter_Required');
  } else {
    return Yup.string().matches(
      /^.*\.(doc|DOC|docx|DOCX|pdf|PDF)$/,
      'Error_CV_CoverLetter_Invalid_File'
    );
  }
};

export { getValidationSchema };
