import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';
import FileSelector from './FileSelector';
import { DocumentsType, JobDetail } from '../../utils/types';
import { FormikErrors, FormikTouched } from 'formik';

type DocumentsProps = {
  handleChange: React.ChangeEventHandler<any>;
  handleBlur: React.FocusEventHandler<any>;
  values: DocumentsType;
  privacyPolicyCheck: boolean;
  privacyPolicyError?: string;
  setPrivacyPolicyCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setCVDocument: React.Dispatch<React.SetStateAction<File | null>>;
  setCLDocument: React.Dispatch<React.SetStateAction<File | null>>;
  errors: FormikErrors<DocumentsType> | undefined;
  touched: FormikTouched<DocumentsType> | undefined;
  jobInformation: JobDetail;
};

const Documents: FC<DocumentsProps> = ({
  handleChange,
  handleBlur,
  values,
  privacyPolicyCheck,
  privacyPolicyError,
  setPrivacyPolicyCheck,
  errors,
  touched,
  jobInformation,
  setCVDocument,
  setCLDocument,
}) => {
  const { t } = useTranslation();
  const checkboxRef = useRef(null);

  return (
    <>
      <Row>
        <Col md="6">
          {jobInformation.formOption.cv !== undefined ? (
            <FileSelector
              label={`${t('Label_CV')}${
                jobInformation.formOption.cv ? '*' : ''
              }`}
              name="documents.cv"
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values.cv}
              error={errors?.cv}
              touched={touched?.cv}
              setCVDocument={setCVDocument}
              setCLDocument={setCLDocument}
            />
          ) : (
            <div></div>
          )}
        </Col>
        <Col md="6">
          {jobInformation.formOption.coverLetter !== undefined ? (
            <FileSelector
              label={`${t('Label_CoverLetter')}${
                jobInformation.formOption.coverLetter ? '*' : ''
              }`}
              name="documents.coverLetter"
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values.coverLetter}
              error={errors?.coverLetter}
              touched={touched?.coverLetter}
              setCVDocument={setCVDocument}
              setCLDocument={setCLDocument}
            />
          ) : (
            <div></div>
          )}
        </Col>
      </Row>
      <Row>
        <Form.Group>
          <Form.Check type="checkbox">
            <Form.Check.Input
              type="checkbox"
              checked={privacyPolicyCheck}
              ref={checkboxRef}
              onClick={() => setPrivacyPolicyCheck(!privacyPolicyCheck)}
            />
            <Form.Check.Label>
              <span onClick={() => setPrivacyPolicyCheck(!privacyPolicyCheck)}>
                {t('PrivacyPolicyAgreementText')}{' '}
              </span>
              <a
                className="privacy-policy-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hubert.ai/candidate-privacy-policy"
              >
                {`${t('PrivacyPolicyLinkText')}`}
              </a>
              {'.'}
            </Form.Check.Label>
          </Form.Check>
          {privacyPolicyError && (
            <Form.Text className="error-message">
              {t(privacyPolicyError)}
            </Form.Text>
          )}
        </Form.Group>
      </Row>
    </>
  );
};

export default Documents;
