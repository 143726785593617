import { FC } from "react";
import { TFunction, withTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

type TextboxProps = {
  type?: string;
  label: string;
  name: string;
  placeholder: string;
  handleChange: React.ChangeEventHandler<any>;
  handleBlur: React.FocusEventHandler<any>;
  value: string | number;
  error?: string;
  touched?: boolean;
  t: TFunction<"translation", undefined>;
};

const Textbox: FC<TextboxProps> = ({
  type = "text",
  label,
  name,
  placeholder,
  handleChange,
  handleBlur,
  value,
  error,
  touched,
  t,
}) => (
  <Form.Group className="form-group">
    <Form.Label>{label}</Form.Label>
    <Form.Control
      className={error && touched ? "error" : ""}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      autoComplete="off"
    />
    {error && touched && (
      <Form.Text className="error-message">{t(error)}</Form.Text>
    )}
  </Form.Group>
);

export default withTranslation()(Textbox);
