import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Container } from 'react-bootstrap';
import LanguageSelector from './components/LanguageSelector';
import TopSection from './components/TopSection';
import ApplyForm from './components/ApplyForm';
import SuccessModal from './components/SuccessModal';
import LoadingScreen from './components/LoadingScreen';
import { JobDetail } from './utils/types';
import { get } from './utils/axios';
import './App.scss';

function App() {
  const { t } = useTranslation();
  const [isModalPopup, setIsModalPopup] = useState(false);
  const [interviewUrl, setInterviewUrl] = useState('#');
  const [serverError, setServerError] = useState('');
  const [jobDetails, setJobDetails] = useState<JobDetail>({
    clientTitle: '',
    jobTitle: '',
    jobDescription:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    formOption: {
      address: true,
      zipCode: true,
      city: true,
      country: true,
      phone: true,
      linkedIn: true,
      cv: true,
      coverLetter: true,
      logo: '',
    },
    jobId: '',
  });

  useEffect(() => {
    getJobDetails();
  }, []);

  const getPathId = () => {
    const currentLocation = window.location;
    let url = new URL(currentLocation.href);
    let pathSegments = url.pathname.split('/');
    if (pathSegments.length == 2) {
      return pathSegments[1];
    } else {
      return '';
    }
  };

  const getJobDetails = async () => {
    const jobId = getPathId();
    const response = await get(`/${jobId}`);
    const data = response.data;
    data.jobId = jobId;
    setJobDetails(data);
  };

  const ErrorAlert = () => (
    <Alert variant="danger" className="mt-4">
      <Alert.Heading>{'Oops!'}</Alert.Heading>
      <div>
        {t(serverError)} <a href="mailto:hubert@hubert.ai">hubert@hubert.ai</a>
      </div>
    </Alert>
  );

  return (
    <>
      {jobDetails.clientTitle !== '' ? (
        <Container
          className={`external-apply-page ${isModalPopup && 'no-scroll'}`}
        >
          <LanguageSelector />
          <TopSection jobInformation={jobDetails} />
          <ApplyForm
            setIsModalPopup={setIsModalPopup}
            jobInformation={jobDetails}
            setServerError={setServerError}
            setInterviewUrl={setInterviewUrl}
          />
          {serverError ? <ErrorAlert /> : ''}
        </Container>
      ) : (
        <LoadingScreen />
      )}
      <div
        className={`apply-page-modal-overlay ${isModalPopup && 'show-modal'}`}
      >
        {isModalPopup ? <SuccessModal interviewUrl={interviewUrl} /> : ''}
      </div>
    </>
  );
}

export default App;
