import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { FormikErrors, FormikTouched } from "formik";
import { GeneralInformationType } from "../../utils/types";
import Textbox from "./Textbox";
import PhoneNumber from "./PhoneNumber";
import { JobDetail } from "../../utils/types";


type GeneralInformationProps = {
  handleChange: React.ChangeEventHandler<any>;
  handleBlur: React.FocusEventHandler<any>;
  values: GeneralInformationType;
  errors: FormikErrors<GeneralInformationType> | undefined;
  touched: FormikTouched<GeneralInformationType> | undefined;
  jobInformation: JobDetail
};

const GeneralInformation: FC<GeneralInformationProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  jobInformation
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col md="6">
          <Textbox
            label={`${t("Label_FirstName")}*`}
            name={"general.firstName"}
            placeholder={t("Placeholder_FirstName")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.firstName}
            error={errors?.firstName}
            touched={touched?.firstName}
          />
        </Col>
        <Col md="6">
          <Textbox
            label={`${t("Label_Surname")}*`}
            name={"general.surname"}
            placeholder={t("Placeholder_Surname")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.surname}
            error={errors?.surname}
            touched={touched?.surname}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Textbox
            label={`${t("Label_EmailAddress")}*`}
            name={"general.emailAddress"}
            placeholder={t("Placeholder_EmailAddress")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.emailAddress}
            error={errors?.emailAddress}
            touched={touched?.emailAddress}
          />
        </Col>
        <Col md="6">
              <PhoneNumber
                label={`${t("Label_PhoneNumber")}${jobInformation.formOption.phone ? "*" : ""}`}
                names={{
                  countryCode: "general.countryCode",
                  phoneNumber: "general.phoneNumber",
                }}
                placeholder={t("Placeholder_PhoneNumber")}
                countryCodePlaceholder={t("Placeholder_CountryCode")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={{
                  countryCode: values.countryCode,
                  phoneNumber: values.phoneNumber,
                }}
                errors={{
                  countryCode: errors?.countryCode,
                  phoneNumber: errors?.phoneNumber,
                }}
                touched={{
                  countryCode: touched?.countryCode,
                  phoneNumber: touched?.phoneNumber,
                }}
              />
        </Col>
      </Row>
      <Row>
        <Col md="6">
        {
            jobInformation.formOption.linkedIn !== undefined ?
          <Textbox
            label={`${t("Label_LinkedIn")}${jobInformation.formOption.linkedIn ? "*" : ""}`}
            name={"general.linkedIn"}
            placeholder={t("Placeholder_LinkedIn")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.linkedIn}
            error={errors?.linkedIn}
            touched={touched?.linkedIn}
          /> : <div></div>
        }
        </Col>
      </Row>
    </>
  );
};

export default GeneralInformation;
