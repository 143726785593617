import React, { FC, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, Button } from 'react-bootstrap';
import Address from './FormSections/Address';
import Documents from './FormSections/Documents';
import GeneralInformation from './FormSections/GeneralInformation';
import { getValidationSchema } from './../utils/validationSchema';
import { postForm } from '../utils/axios';
import { JobDetail } from '../utils/types';
import { getFileExtentionByType } from '../utils/utilities';
import { v4 as uuidv4 } from 'uuid';
import parsePhoneNumber from 'libphonenumber-js';

type ApplyFormProps = {
  setIsModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setServerError: React.Dispatch<React.SetStateAction<string>>;
  setInterviewUrl: React.Dispatch<React.SetStateAction<string>>;
  jobInformation: JobDetail;
};

const ApplyForm: FC<ApplyFormProps> = ({
  setIsModalPopup,
  jobInformation,
  setServerError,
  setInterviewUrl,
}) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [privacyPolicyCheck, setPrivacyPolicyCheck] = useState(false);
  const [privacyPolicyError, setPrivacyPolicyError] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [cvDocument, setCVDocument] = useState<File | null>(null);
  const [clDocument, setCLDocument] = useState<File | null>(null);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        general: {
          firstName: '',
          surname: '',
          emailAddress: '',
          countryCode: '+46',
          phoneNumber: '',
          linkedIn: '',
        },
        address: { street: '', zipCode: '', city: '', country: '' },
        documents: { cv: '', coverLetter: '' },
      },
      validationSchema: getValidationSchema(jobInformation.formOption),
      validate: (values) => {
        console.log(values.general.countryCode, values.general.phoneNumber);
        const errors: any = {};

        if (values.general.countryCode && values.general.phoneNumber) {
          console.log('validating phone number');
          const phoneNumber =
            values.general.countryCode + values.general.phoneNumber;
          const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

          console.log(parsedPhoneNumber?.isValid());

          if (!parsedPhoneNumber?.isValid()) {
            errors.general = {
              ...errors.general,
              phoneNumber: 'Error_PhoneNumber_Invalid',
            };
          }
        }

        console.log(errors);

        return errors;
      },
      onSubmit: async (values) => {
        setServerError('');
        if (privacyPolicyCheck) {
          setSubmitEnabled(false);
          const token = await handleReCaptchaVerify();
          if (token) {
            try {
              var form = new FormData();
              form.append('firstName', values.general.firstName);
              form.append('surname', values.general.surname);
              form.append('emailAddress', values.general.emailAddress);
              form.append('countryCode', values.general.countryCode);
              form.append('phoneNumber', values.general.phoneNumber);
              form.append('linkedIn', values.general.linkedIn);
              form.append('street', values.address.street);
              form.append('city', values.address.city);
              form.append('zipCode', values.address.zipCode);
              form.append('country', values.address.country);
              form.append('token', token);
              if (cvDocument) {
                form.append(
                  'cv',
                  cvDocument,
                  `${uuidv4()}${getFileExtentionByType(cvDocument.type)}`
                );
              }
              if (clDocument) {
                form.append(
                  'cl',
                  clDocument,
                  `${uuidv4()}${getFileExtentionByType(clDocument.type)}`
                );
              }

              const res = await postForm(
                `/application/${jobInformation.jobId}`,
                form
              );
              const redirectUrl = res.data.redirectUrl;

              if (redirectUrl) {
                setInterviewUrl(redirectUrl);
                setIsModalPopup(true);
              } else {
                setServerError('Error_ServerError');
              }
            } catch (e) {
              console.error(e);
              setServerError('Error_ServerError');
            }
          } else {
            setServerError('Error_ServerError');
          }
          setSubmitEnabled(true);
        } else {
          setPrivacyPolicyError('Error_PrivacyPolicyCheckbox');
        }
      },
    });

  useEffect(() => {
    if (privacyPolicyCheck) setPrivacyPolicyError('');
  }, [privacyPolicyCheck]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    return await executeRecaptcha('jobApplySubmit');
  }, [executeRecaptcha]);

  const addressVisible =
    jobInformation.formOption.address != null ||
    jobInformation.formOption.zipCode != null ||
    jobInformation.formOption.city != null ||
    jobInformation.formOption.country != null;

  return (
    <>
      <Form className={'form-wrapper'} onSubmit={handleSubmit}>
        <div className="form-section">
          <div className="form-heading">
            {t('FormHeading_GeneralInformation')}
          </div>
          <GeneralInformation
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values.general}
            errors={errors.general}
            touched={touched.general}
            jobInformation={jobInformation}
          />
        </div>
        {addressVisible ? (
          <div className="form-section">
            <div className="form-heading">{t('FormHeading_Address')}</div>
            <Address
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values.address}
              errors={errors.address}
              touched={touched.address}
              jobInformation={jobInformation}
            />
          </div>
        ) : (
          <div></div>
        )}
        <div className="form-section">
          {jobInformation.formOption.coverLetter !== undefined ||
          jobInformation.formOption.cv !== undefined ? (
            <div className="form-heading">{t('FormHeading_Documents')}</div>
          ) : (
            <div></div>
          )}
          <Documents
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values.documents}
            errors={errors.documents}
            touched={touched.documents}
            privacyPolicyCheck={privacyPolicyCheck}
            privacyPolicyError={privacyPolicyError}
            setPrivacyPolicyCheck={setPrivacyPolicyCheck}
            jobInformation={jobInformation}
            setCVDocument={setCVDocument}
            setCLDocument={setCLDocument}
          />
        </div>
        <div className="btn-wrapper">
          <Button variant="primary" type="submit" disabled={!submitEnabled}>
            {t('ButtonText_Apply')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ApplyForm;
